import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { useStyles,drawerWidth } from "../../layout/styles";
import logo from '../../../assets/img/logo.png'


export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const width = "450px";
  const height = "200px";

  useEffect(() => {

  }, []);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" style={{textAlign:"center"}}>
            <img src={logo} style={{width:"300px"}}/>
            <Grid container style={{margin: '50px 0', textAlign:"center"}}>
                <Grid item sm={12} style={{textAlign:'center',margin: '10px 0',}}>
                  <Button variant="contained" color="primary" style={{width:width, height:height}} onClick={() => history.push("/inmueble/1")}> Quiero comprar </Button>
                </Grid>
                <Grid item sm={12} style={{textAlign:'center',margin: '10px 0',}}>
                  <Button variant="contained" color="primary" style={{width:width, height:height}} onClick={() => history.push("/inmueble/2")}> Quiero alquilar </Button>
                </Grid>
                <Grid item sm={12} style={{textAlign:'center',margin: '10px 0',}}>
                  <Button variant="contained" color="primary" style={{width: width , height:height}}  onClick={() => history.push("/vender")}> Quiero vender </Button>
                </Grid>
            </Grid>
        </Container>
      </main>
    </div>
  );
}
