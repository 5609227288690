import React from 'react';
import {BrowserRouter as Router, Route, Redirect,Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Inmueble from './component/page/inmueble/Inmueble';
import Home from './component/page/home/Home';
import Vender from './component/page/vender/Vender';
import DetalleInmueble from './component/page/detalleinmueble/DetalleInmueble';

export default function App() {


  return (
    <>
    <Router>
      <Switch>
        <Route path="/vender" component={Vender} />
        <Route path="/inmueble/:venta" component={Inmueble} />
        <Route path="/detalleinmueble/:id" component={DetalleInmueble} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
    </>
  );
}
