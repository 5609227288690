import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

export const getValueField = (object,field,size='80px',parserImg:any = undefined) => {
  if(object){
    if (field.includes(".")){
      const parts = field.split(".");
      var objfield = object[parts[0]];
      if(Array.isArray(objfield)){
        var result = '';
        for (let entry of objfield) {
          result = result + getValueField(entry,field.substring(parts[0].length +1)) + '\n';
        }
        return result;
      } else {
        var r = getValueField(objfield,field.substring(parts[0].length +1));
        if (typeof r === "boolean" && r){
          return <CheckIcon  style={{color:"green"}}/>
        } else if (typeof r === "boolean" && r){
          return <ClearIcon style={{color:"red"}}/>
        } else {
          return r
        }
      }
    } else {
      if(field == 'image' && object[field] && object[field] != '') {
        const url : string = getUrlImage( object[field]) + '';
        if (parserImg){
          return parserImg(url)
        } else {
          return  <img src={url} style={{  width:'auto',  height:size}} /> ;
        }
      } else if(field == 'picture' && object[field] && object[field] != '') {
        const url = getUrlImage( object[field]) + '';
        if (parserImg){
          return parserImg(url)
        } else {
          return  <img src={url} style={{  width:'auto',  height:size}} /> ;
        }
      } else {
        var r = object[field];
        if (typeof r === "boolean" && r){
          return <CheckIcon style={{color:"green"}}/>
        } else if (typeof r === "boolean" && !r){
          return <ClearIcon style={{color:"red"}}/>
        } else{
          return r
        }
      }

    }
  } else {
    return '';
  }
};

export const getValueFieldRaw = (object,field) => {
  if(object){
    if (field.includes(".")){
      const parts = field.split(".");
      var objfield = object[parts[0]];
      if(Array.isArray(objfield)){
        var result = '';
        for (let entry of objfield) {
          result = result + getValueFieldRaw(entry,field.substring(parts[0].length +1)) + '\n';
        }
        return result;
      } else {
        var r = getValueFieldRaw(objfield,field.substring(parts[0].length +1));
        return r
      }
    } else {
      var r = object[field];
      return r;
    }
  } else {
    return '';
  }
};

export const getUrlImage = function(namefile){

  if(namefile && (typeof namefile === 'string' || namefile instanceof String)) {
    if (namefile.startsWith("http")) {
      return namefile;
    } else {
      var nameImage =namefile;
      nameImage = nameImage.startsWith("/")?nameImage:"/"+nameImage;
      return process.env.REACT_APP_HOST+'/image'+nameImage;
    }
  } else{
    return '';
  }
}

export const getImage = function(row,size='50px'){
  const url = getUrlImage(row['image']) + '';
  return  <img src={url} style={{  width:'auto',  height:size,  maxWidth: '100%'}} /> ;
}

export const getImageCell = function(row,name){
  const url = getUrlImage(row[name]) + '';
  return  <img src={url} style={{  width:'100%',  height:"auto"}} /> ;
}


export const getNameToUrl = (nameaux) => {
  let name:string = nameaux || '';
  return escape(encodeURIComponent(name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, '-')))
}

export const getCouponToUrl = (nameaux) => {
  let name:string = nameaux || '';
  return escape(encodeURIComponent(name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, '')))
}


export  const preparetextblo = (text:string) =>{
     var urlBase = process.env.REACT_APP_HOST+'/image/'
     if(text) {
       text = text.replaceAll('/uploads/',urlBase);
       text = text.replaceAll('<a','<a target="_black"');
       return text;

     } else {
       return '';
     }
}

export  const preparetextforo = (text:string) =>{
     if(text) {
       text = text.replace('time_continue=1&amp;','');
       text = text.replace('https://vimeo.com/','###VIMEO##');
       text = text.replace('https://www.youtube.com/watch?v=','###YOUTUBE##');
       const url = /(http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
       const urlpart = text.match(url);
       if(urlpart) {
         text = text.replace(url, '<a href="##URL##" target="_blank" title="">##URL##</a>');
         text = text.replace('##URL##', urlpart[0]);
         text = text.replace('##URL##', urlpart[0]);
       }
       var frame = /###VIMEO##[0-9]+(\/\S*)?/;
       const vim = text.replace(frame, '<iframe src="https://player.vimeo.com/video/##VIMEO_URL##" width="640" height="360" style="max-width:80%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
       text = vim.replace('##VIMEO_URL##',text);
       text = text.replace('###VIMEO##','');
       var frameyou = /###YOUTUBE##[a-zA-Z0-9\-\.]+(\/\S*)?/;
       const you = text.replace(frameyou, '<iframe width="560" height="315" src="https://www.youtube.com/embed/##YOU_URL##"  style="max-width:80%" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
       text = you.replace('##YOU_URL##',text);
       text = text.replace('###YOUTUBE##','');

       return text;

     } else {
       return '';
     }
}

export const parserImgHeader = (url) => {
    return <div style={{  width:'200px',  height:'200px',  padding: '25px', background:'white', borderRadius: '100px', display: 'block', verticalAlign: 'middle', overflow:'hidden',backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "90% auto", backgroundImage: "url("+url+")" }}></div> ;
}

export const isAdmin = () =>{
  const user:string = localStorage.getItem("user")+'';
  const userObject = JSON.parse(user);

  return userObject.permissions.includes('ADMIN');
}
