import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';import clsx from 'clsx';
import Link from '@material-ui/core/Link';

export default function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.bafreinmobiliaria.es">
        BAFRE GESTION Y SERVICIOS INMOBILIARIOS S.L
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
