import React, { useState, useEffect }  from 'react';
import { useHistory, useParams } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import {inmuebleService} from "../../../services"
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";




const initObject =  {
  ANO_CONSTRUCCION: "",
  ASESOR: "",
  BANOS: 0,
  CALLE: "",
  CIUDAD: "",
  CP: "",
  DESCRIPCION_INMUEBLE: "",
  DIRECCION_CALENDARIO: "",
  DIRECCION_GMAIL: "",
  ESCALERA: "",
  ESFAV: 0,
  ESTADO_INMUEBLE: "",
  FECHA_PASO_ACTIVO: "",
  FINALIDAD: "",
  FOTOS: null,
  FOTO_PRIN: null,
  HABITACIONES: 2,
  IDASCENSOR: 0,
  IDCAPACIDAD_PLAZA: 0,
  IDCARACTERISTICAS_PISO: "",
  IDCLIENTE_INQUILINO: 0,
  IDCLIENTE_PROPIETARIO: 0,
  IDCOMUNIDADAUTONOMA: 0,
  IDDISTRITO: 0,
  IDEQUIPAMIENTOS_LOCAL: 0,
  IDESTADOINMUEBLE: 0,
  IDEXTERIORES_INMUEBLE: "",
  IDFINALIDAD: 1,
  IDFINCA_INMUEBLE: "",
  IDINMUEBLE: 0,
  IDMUNICIPIO: 0,
  IDOFICINA: 0,
  IDORIENTACION: "",
  IDPROMOCION: 0,
  IDREGION: 1,
  IDTIENE_ASCENSOR: -1,
  IDTIENE_GARAJE: -1,
  IDTIPOCONTRATO: 0,
  IDTIPOINMUEBLE: 0,
  IDTIPOLOGIAINMUEBLE: 0,
  IDTIPOUSOPERMITIDO: 0,
  IDTIPO_LOCAL: 0,
  IDUSUARIO_ASESOR_CLIENTE: 0,
  IDVISIBILIDAD_PORTALES: 0,
  IDZONASINMUEBLE: 0,
  LATITUD: "",
  LLAVES: "",
  LONGITUD: "",
  MUNICIPIO: "",
  NUMERO: "",
  NUM_VISITAS: 0,
  OFICINA: "",
  PAIS: "",
  PARKING: "",
  PISO: "0",
  PLANTA: "",
  PRECIO: "",
  PRECIO_REBAJADO: "",
  PUERTA: "",
  REFERENCIA: "",
  REFERENCIA2: "",
  SUPERFICIE: "",
  TELEFONO_OFICINA: "",
  TIPOLOGIA_INMUEBLE: "Piso",
  TITULO_INMUEBLE: "aa",
  ZONAS_INMUEBLE: "Pradolongo",
  direccion_referencia: "",
  fecreacion: "",
  idplanta: 0
};



export default function DetalleInmueble() {
  const classes = useStyles();
  const history = useHistory();
  let { id } = useParams();
  const filter =  history.location.state?.filter;
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [object, setObject] = useState(initObject);
  const [images, setImages] = useState([{}]);

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const search = () => {
    inmuebleService.one(id, (data, error) => {
        if(data){
            setObject(currentObject => ({...currentObject, ...data}));

            var imagesInmueble = Array();

            if(data['FOTOS']){
              for (const f in data['FOTOS']) {
                  var fi = data['FOTOS'][f];
                  var img = {
                      original: fi["URL"],
                      thumbnail:  fi["URL"],
                  };
                  imagesInmueble.push(img);
              }
              setImages(imagesInmueble);
            }

        }
    });
  }


  useEffect(() => {
    search();
  }, []);



  const goToBack = function(event){
      history.push({pathname:"/inmueble/1", state:{filter:filter}});
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={"Detalle de inmueble"} classes={classes} open={false} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} goToBack={goToBack}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg">
            <Grid container style={{marginBottom: '50px'}}>
              <Grid xs={12} sm={12} style={{marginBottom: '50px'}}>
                <ImageGallery items={images} lazyLoad={true} autoPlay={false} showPlayButton={false} thumbnailPosition={"bottom"}/>
              </Grid>
              <Grid xs={12} sm={12} style={{marginBottom: '50px'}}>
                <Typography> <label style={{fontSize:'1.3em',fontWeight:'bold'}}>{object['TITULO_INMUEBLE']}</label>  </Typography>
              </Grid>
            </Grid>
            <div style={{backgroundColor:"#EDEDED", padding:"1em"}}>
              <Grid container >
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Precio</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['PRECIO']} €</label>
                </Grid>
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>ID</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['REFERENCIA']}</label>
                </Grid>
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Inmueble</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['TIPOLOGIA_INMUEBLE']}</label>
                </Grid>
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Contrato</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['FINALIDAD']}</label>
                </Grid>
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Localizacion</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['CALLE']}</label>
                </Grid>
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Metros</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['SUPERFICIE']} m<sup>2</sup></label>
                </Grid>
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Baños</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['BANOS']}</label>
                </Grid>
                <Grid xs={6} sm={8} style={{}}>
                  <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Dormitorios</label>
                </Grid>
                <Grid xs={6} sm={4} style={{textAlign:'right'}}>
                    <label style={{fontSize:'1.3em'}}>{object['HABITACIONES']}</label>
                </Grid>
              </Grid>
            </div>
            <Grid container >
              <Grid xs={12} sm={12} style={{marginTop:"50px"}}>
                <div style={{fontSize:'1.3em'}} dangerouslySetInnerHTML={{ __html: object['DESCRIPCION_INMUEBLE'] }} ></div>
              </Grid>
            </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
