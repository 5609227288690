import BaseService from './_base.service';

export const inmuebleService = {
    find,
    finalidad,
    comunidadautonoma,
    regiones,
    municipios,
    distrito,
    tipoinmueble,
    one
};

function find(offset,query,callback) {
  return BaseService.get("api/v1/inmueble/find/"+offset+(query ? "?" + query : ""), callback)
}
function one(id,callback) {
  return BaseService.get("api/v1/inmueble/"+id, callback)
}

function finalidad(callback) {
  return BaseService.get("api/v1/finalidad", callback)
}


function tipoinmueble(callback) {
  return BaseService.get("api/v1/tipologiainmueble", callback)
}

function comunidadautonoma(callback) {
  return BaseService.get("api/v1/comunidadautonoma", callback)
}

function regiones(comunidad,callback) {
  return BaseService.get("api/v1/comunidadautonoma/"+comunidad+"/region", callback)
}

function municipios(comunidad,callback) {
  return BaseService.get("api/v1/region/"+comunidad+"/municipio", callback)
}

function distrito(comunidad,callback) {
  return BaseService.get("api/v1/municipio/"+comunidad+"/distrito", callback)
}
