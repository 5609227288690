import React from 'react';
import { useParams,useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import logo from '../../assets/img/logoletras.png'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';

export default function Header(props) {
  const history = useHistory();
  const hiddenalert = true;

  const closeSession = function(){
    localStorage.clear();
    history.push('/');
  }

  const goToBack = function(event){
    if (props.goToBack){
      props.goToBack();
    } else {
       history.goBack();
    }
  }

  return (
     <AppBar position="absolute" className={clsx(props.classes.appBar, props.open && props.classes.appBarShift)}>
        <Toolbar className={props.classes.toolbar}>
          <div style={{width:"200px"}}>
            <ArrowBackIcon style={{marginRight:"10px", fontSize:"5em", cursor:"pointer"}} onClick={goToBack}/>
            <HomeIcon  style={{ fontSize:"5em", cursor:"pointer"}} onClick={closeSession}/>
          </div>
          <div style={{width:"calc(100% - 400px)", textAlign:"center"}}><img src={logo} className={props.classes.logoheader} /></div>
          <div style={{width:"200px"}}></div>
        </Toolbar>
      </AppBar>
  );
}
