import React, { useState, useEffect }  from 'react';
import { useHistory, useParams } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Form from "../../form/Form"
import backhome from '../../../assets/img/backhome.png'
import TableHouse from "./TableHouse"
import {inmuebleService} from "../../../services"



const initFilter =  {
    tipoinmueble : "",
    municipio : "1",
    supermin : "",
    supermax : "",
    habmin : "",
    habmax : "",
    banmin : "",
    banmax : "",
    precmin : "",
    precmax : "",
    referencia : "",
    comunidad : "1",
    finalidad : "",
    region : "1",
    distrito : "",
    garajemin : "",
    garajemax : "",
    buscarInmuebleFiltroOrdenacion : null,
};



export default function Inmueble() {
  const classes = useStyles();
  const history = useHistory();
  let { venta } = useParams();
  initFilter['finalidad'] = venta;
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = useState(0);
  const [rows,setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [rowsTotal,setRowsTotal] = useState(0);
  const [filter, setFilter] = useState( history.location.state && history.location.state.filter ? history.location.state.filter : initFilter);
  const fieldstable = [];
  const headersTable =  [];
  const service =  null;
  const urledit =  "";
  const urlnew =  "";
  const titlePage =  "";
  const fieldId =  "id";
  const titleButton =  "Nuevo";

  const [open, setOpen] = useState(true);


  const estructureForm =  [
     {
       type:"autocomplete",
       name: "finalidad",
       label: "Alquiler o compra",
       service:inmuebleService.finalidad,
       labeloption:"FINALIDAD",
       valueoption:"IDFINALIDAD",
       relationfield:'precmax',
       servicerelation:inmuebleService.finalidad,
       relationSearch:(setOptions,objectform,findInOptionsWithOptions,field) => {
          debugger;
           if (objectform["finalidad"] != null && (objectform["finalidad"] == 1 ||objectform["finalidad"] == 3) ){
             var optprecios = [{key:0,name:"0 €"},{key:50000,name:"50.000 €"},{key:100000,name:"100.000 €"},{key:150000,name:"150.000 €"},{key:200000,name:"200.000 €"},{key:250000,name:"250.0000 €"},{key:3000000,name:"300.000 €"},{key:350000,name:"350.000 €"},{key:400000,name:"4000.000 €"},{key:400000,name:"400.000 €"},{key:450000,name:"450.000 €"},{key:500000,name:"5000.000 €"},{key:550000,name:"550.000 €"}];
             setOptions(currentOptions => ({...currentOptions, ["precmax"]: optprecios }));
             findInOptionsWithOptions(optprecios,field,objectform)
           } else if (objectform["finalidad"] != null && objectform["finalidad"] == 2){
             if(objectform['tipoinmueble'] && objectform['tipoinmueble'] == 3) {

               var optprecios = [{key:500,name:"0 - 500€"},{key:1000,name:"500 - 1000€"},{key:1500,name:"1000 - 1500€"},{key:2000,name:"1500 - 2000€"},{key:2500,name:"2000 - 2500€"},{key:2500000,name:"+2500€"}];
               setOptions(currentOptions => ({...currentOptions, ["precmax"]: optprecios }));
               findInOptionsWithOptions(optprecios,field,objectform)
             } else {
               var optprecios = [{key:500,name:"0 - 500€"},{key:600,name:"500 - 600€"},{key:700,name:"600 - 700€"},{key:800,name:"700 - 800€"},{key:900,name:"800 - 900€"},{key:1000,name:"900 - 1000€"},{key:1100,name:"1000 - 1100€"},{key:1200,name:"1100 - 1200€"},{key:1300,name:"1200 - 1300€"},{key:1400,name:"1300 - 1400€"},{key:1500,name:"1400 - 1500€"},{key:1600,name:"1500 - 1600€"},{key:1700,name:"1600 - 1700€"},{key:1800,name:"1700 - 1800€"},{key:1900,name:"1800 - 1900€"},{key:2000,name:"1900 - 2000€"},{key:200000,name:"+2000€"}];
               setOptions(currentOptions => ({...currentOptions, ["precmax"]: optprecios }));
               findInOptionsWithOptions(optprecios,field,objectform)
             }
           }
       },
       col: 6
      },
      {
       type:"autocomplete",
       name: "tipoinmueble",
       label: "Tipo de propiedad",
       service:inmuebleService.tipoinmueble,
       labeloption:"TIPOLOGIA_INMUEBLE",
       valueoption:"IDTIPOLOGIAINMUEBLE",
       relationfield:'precmax',
       servicerelation:inmuebleService.finalidad,
       relationSearch:(setOptions,objectform,findInOptionsWithOptions,field) => {
          debugger;
           if (objectform["finalidad"] != null && (objectform["finalidad"] == 1 ||objectform["finalidad"] == 3) ){
             var optprecios = [{key:0,name:"0 €"},{key:50000,name:"50.000 €"},{key:100000,name:"100.000 €"},{key:150000,name:"150.000 €"},{key:200000,name:"200.000 €"},{key:250000,name:"250.0000 €"},{key:3000000,name:"300.000 €"},{key:350000,name:"350.000 €"},{key:400000,name:"4000.000 €"},{key:400000,name:"400.000 €"},{key:450000,name:"450.000 €"},{key:500000,name:"5000.000 €"},{key:550000,name:"550.000 €"}];
             setOptions(currentOptions => ({...currentOptions, ["precmax"]: optprecios }));
             findInOptionsWithOptions(optprecios,field,objectform)
           } else if (objectform["finalidad"] != null && objectform["finalidad"] == 2){
             if(objectform['tipoinmueble'] && objectform['tipoinmueble'] == 3) {

               var optprecios = [{key:500,name:"0 - 500€"},{key:1000,name:"500 - 1000€"},{key:1500,name:"1000 - 1500€"},{key:2000,name:"1500 - 2000€"},{key:2500,name:"2000 - 2500€"},{key:2500000,name:"+2500€"}];
               setOptions(currentOptions => ({...currentOptions, ["precmax"]: optprecios }));
               findInOptionsWithOptions(optprecios,field,objectform)
             } else {
               var optprecios = [{key:500,name:"0 - 500€"},{key:600,name:"500 - 600€"},{key:700,name:"600 - 700€"},{key:800,name:"700 - 800€"},{key:900,name:"800 - 900€"},{key:1000,name:"900 - 1000€"},{key:1100,name:"1000 - 1100€"},{key:1200,name:"1100 - 1200€"},{key:1300,name:"1200 - 1300€"},{key:1400,name:"1300 - 1400€"},{key:1500,name:"1400 - 1500€"},{key:1600,name:"1500 - 1600€"},{key:1700,name:"1600 - 1700€"},{key:1800,name:"1700 - 1800€"},{key:1900,name:"1800 - 1900€"},{key:2000,name:"1900 - 2000€"},{key:200000,name:"+2000€"}];
               setOptions(currentOptions => ({...currentOptions, ["precmax"]: optprecios }));
               findInOptionsWithOptions(optprecios,field,objectform)
             }
           }
       },
       col: 6
     },
      {
       type:"autocomplete",
       name: "comunidad",
       label: "Comunidad autonoma",
       service:inmuebleService.comunidadautonoma,
       labeloption:"COMUNIDAD_AUTONOMA",
       valueoption:"IDCOMUNIDADAUTONOMA",
       relationfield:'region',
       servicerelation:inmuebleService.regiones,
       relationSearch:(setOptions,objectform,findInOptionsWithOptions,field) => {
           if (objectform["comunidad"] != null){
               inmuebleService.regiones(objectform["comunidad"] ,(data, error) => {
                 if(data && data){

                   setOptions(currentOptions => ({...currentOptions, ["region"]: data }));
                   findInOptionsWithOptions(data,field,objectform)
                 }
               });
           }
       },
       col: 3
      },
      {
       type:"autocomplete",
       name: "region",
       label: "Region",
       labeloption:"NOMBRE",
       valueoption:"IDREGION",
       relationfield:'municipio',
       servicerelation:inmuebleService.municipios,
       relationSearch:(setOptions,objectform,findInOptionsWithOptions,field) => {
           if (objectform["region"] != null){
               inmuebleService.municipios(objectform["region"] ,(data, error) => {
                 if(data && data){

                   setOptions(currentOptions => ({...currentOptions, ["municipio"]: data }));
                   findInOptionsWithOptions(data,field,objectform)
                 }
               });
           }
       },
       col: 3
      },
      {
       type:"autocomplete",
       name: "municipio",
       label: "Municipio",
       labeloption:"MUNICIPIO",
       valueoption:"IDMUNICIPIO",
       relationfield:'municipio',
       servicerelation:inmuebleService.distrito,
       relationSearch:(setOptions,objectform,findInOptionsWithOptions,field) => {
           if (objectform["municipio"] != null){
               inmuebleService.distrito(objectform["municipio"] ,(data, error) => {
                 if(data && data){

                   setOptions(currentOptions => ({...currentOptions, ["distrito"]: data }));
                 }
               });
           }
       },
       col: 3
      },
      {
       type:"autocomplete",
       name: "distrito",
       label: "Distrito",
       labeloption:"DISTRITO",
       valueoption:"IDDISTRITO",
       options:[],
       col: 3
      },
      {
       type:"autocomplete",
       name: "habmin",
       label: "Habitaciones",
       options:[{key:0,name:"0"},{key:1,name:"1"},{key:2,name:"2"},{key:3,name:"3"},{key:4,name:"4"},{key:5,name:"5"},{key:6,name:"6"},{key:7,name:"7"},{key:8,name:"8"}],
       col: 3
      },
      {
       type:"autocomplete",
       name: "banmin",
       label: "Baños",
       options:[{key:0,name:"0"},{key:1,name:"1"},{key:2,name:"2"},{key:3,name:"3"},{key:4,name:"4"},{key:5,name:"5"},{key:6,name:"6"},{key:7,name:"7"},{key:8,name:"8"}],
       col: 3
      },
      {
         type:"autocomplete",
         name: "garajemax",
         label: "Garaje",
         options:[{key:0,name:"No"},{key:100,name:"Si"},{key:-1,name:"Indiferente"}],
         col: 3
       },
       {
        type:"autocomplete",
        name: "precmax",
        label: "Precio. Max",
        options:[{key:0,name:"0 €"},{key:50000,name:"50.000 €"},{key:100000,name:"100.000 €"},{key:150000,name:"150.000 €"},{key:200000,name:"200.000 €"},{key:250000,name:"250.0000 €"},{key:3000000,name:"300.000 €"},{key:350000,name:"350.000 €"},{key:400000,name:"4000.000 €"},{key:400000,name:"400.000 €"},{key:450000,name:"450.000 €"},{key:500000,name:"5000.000 €"},{key:550000,name:"550.000 €"}],
        col: 3
       },
       {
         type:"autocomplete",
         name: "buscarInmuebleFiltroOrdenacion",
         label: "Ordenado por:",
         options:[{campoOrdenacion:"PRECIO", nombre:"Precio menor a mayor", ordenacionAscDesc:"asc"},{campoOrdenacion:"SUPERFICIE", nombre:"Sup. mayor a menor", ordenacionAscDesc:"desc"}],
         labeloption:"nombre",
         valueoption:"COMPLETE",
         col: 3
      },
    ];


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);
  }


  const search = (page,size) => {
    setPage(page);
    setRowsPerPage(size);
    inmuebleService.find(page * size,getQuery(filter), (data, error) => {

        if(data){
          console.log(data);
          setRows(data);
          setRowsTotal(data.length);
        } else {
          setRows([]);
          setRowsTotal(0);
        }
    });
  }

  const getQuery = (filter) => {
    var query = "obra=2";
    var count = 1;
    if (filter.finalidad && filter.finalidad != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "finalidad="+filter.finalidad;
       count++;
    }
    if (filter.tipoinmueble && filter.tipoinmueble != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "tipoinmueble=" + filter.tipoinmueble;
       count++;
    }
    if (filter.municipio && filter.municipio != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "municipio=" + filter.municipio;
       count++;
    }
    if (filter.comunidad && filter.comunidad != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "comunidad=" + filter.comunidad;
       count++;
    }
    if (filter.region && filter.region != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "region=" + filter.region;
       count++;
    }
    if (filter.distrito && filter.distrito != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "distrito=" + filter.distrito;
       count++;
    }
    if (filter.referencia && filter.referencia != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "referencia=" + filter.referencia;
       count++;
    }
    if (filter.supermin && filter.supermin != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "supermin=" + filter.supermin;
       count++;
    }
    if (filter.supermax && filter.supermax != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "supermax=" + filter.supermax;
       count++;
    }
    if (filter.habmin && filter.habmin != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "habmin=" + filter.habmin;
       count++;
    }
    if (filter.habmax && filter.habmax != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "habmax=" + filter.habmax;
       count++;
    }
    if (filter.banmin && filter.banmin != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "banmin=" + filter.banmin;
       count++;
    }
    if (filter.banmax && filter.banmax != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "banmax=" + filter.banmax;
       count++;
    }
    if (filter.precmin && filter.precmin != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "precmin=" + filter.precmin;
       count++;
    }
    if (filter.precmax && filter.precmax != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "precmax=" + filter.precmax;
       count++;
    }
    if (filter.garajemin && filter.garajemin != ""){
       if(count > 0){
         query = query + "&";
       }
       query = query + "garajemin=" + filter.garajemin;
       count++;
    }
    if ((filter.garajemax && filter.garajemax != "" && filter.garajemax != "-1") || filter.garajemax == "0"){
       if(count > 0){
         query = query + "&";
       }
       query = query + "garajemax=" + filter.garajemax;
       count++;
    }

    if (filter.buscarInmuebleFiltroOrdenacion && filter.buscarInmuebleFiltroOrdenacion['campoOrdenacion'] && filter.buscarInmuebleFiltroOrdenacion['ordenacionAscDesc']){
       if(count > 0){
         query = query + "&";
       }
        query = query + "orderfield=" + filter.buscarInmuebleFiltroOrdenacion['campoOrdenacion'];
        query = query + "&";
        query = query + "order=" + filter.buscarInmuebleFiltroOrdenacion['ordenacionAscDesc'];
       count++;
    }


    return query;
  }


  useEffect(() => {
    search(0,rowsPerPage);
  }, []);


  const deleteHandler = (id) => {

  }

  const eventSelect = (id) => {
      history.push({pathname: "/detalleinmueble/"+id, state: {filter:filter}});
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titlePage} classes={classes} open={false} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div style={{height:"450px", background:"url('"+backhome+"')", width: "100%", zIndex:-1}}></div>
        <Container maxWidth="lg" style={{marginTop:"-350px"}}>
            <Grid container style={{marginBottom: '50px'}}>
                <Form objectform={filter} fields={estructureForm}  searchFilter={searchFilter} cleanFilter={cleanFilter} labelsave={"Buscar"} labelcancel={"Borrar filtros"}/>
            </Grid>
            <Grid container style={{marginBottom: '50px'}}>
              <TableHouse rows={rows} fieldId={"id"} rowsPerPage={rowsPerPage} rowsTotal={rowsTotal} changePage={changePageTable} page={page} selectHandler={eventSelect}/>
            </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
