import BaseService from './_base.service';

export const uploadService = {
    upload,
    uploadWithName
};

function upload(image, callback) {
    return BaseService.postMultipart("/upload/", image, callback, true)
}

function uploadWithName(image,name, callback) {
    return BaseService.postMultipartWithName("/upload/", image, name, callback, true)
}
