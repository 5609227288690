import React, { useState, useEffect, useRef, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {uploadService} from "../../services";
import { Editor } from '@tinymce/tinymce-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormTable from "./FormTable";


const moment = require("moment");


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  inputform: {
    width: '100%',
    background: "#E6E2E1",
    color:"black",
    padding:"0.2em 0.5em"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 300,
    textAlign:"left"
  },
}));

export default function Form(props) {
  const classes = useStyles();
  const [objectform, setObjectform] = useState(props.objectform?props.objectform:{});
  const editorRef = useRef(null);
  const [fields, setFields] = useState(props.fields?props.fields:[]);
  const [labelsave, setLabelsave] = useState(props.labelsave?props.labelsave:'Guardar');
  const [labelcancel, setLabelcancel] = useState(props.labelcancel?props.labelcancel:'Cancelar');
  const [isHiddenSave, setIsHiddenSave] = useState(props.isHiddenSave?props.isHiddenSave:false);
  const [isHiddenCancel, setIsHiddenCancel] = useState(props.isHiddenCancel?props.isHiddenCancel:false);
  const [cleanInOK, setCleanInOK] = useState(props.cleanInOK?props.cleanInOK:false);
  const [loadingCirculation, setLoadingCirculation] = useState(true);
  const [optionsCred, setOptionsCred] = useState([]);
  const [setValueAux, setSetValueAux] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      if (field.setvalue) {
         valuesfields[field.name] = field.setvalue;
      }

    }
    return valuesfields;
  });
  const [relationSearchAux, setRelationSearchAux] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      if (field.relationSearch) {
         valuesfields[field.name] = field.relationSearch;
      }

    }
    return valuesfields;
  });
  const [valueform, setValueform] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      valuesfields[field.name] = "";
    }
    return valuesfields;
  });
  const [options, setOptions] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      if(field.type == 'autocomplete' && !field.options) {
        var fn = field.name;
        valuesfields[fn] = Array();
        //valuesfields[fn].push({key: -1, name:"Selecciona una opción"})
      } else if(field.type == 'autocomplete' && field.options) {
        var fn = field.name;
        valuesfields[fn] = field.options;
      }
    }
    return valuesfields;
  });

  const [clear, setClear] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      var fn = field.name;
      if(field.clear) {
        valuesfields[fn] = field.clear;
      } else  {
        valuesfields[fn] = [];
      }
    }
    return valuesfields;
  });
  const [handlers, setHandlers] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      if(field.type == 'autocomplete' && field.handler) {
        valuesfields[field.name] = field.handler;
      }
    }
    return valuesfields;
  });
  const [servicerelation, setServicerelation] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      if(field.type == 'autocomplete' && field.servicerelation) {
        valuesfields[field.name] = field.servicerelation;
      }
    }
    return valuesfields;
  });
  const [relationfield, setRelationfield] = useState(() => {
    var valuesfields = Array();
    for (let field of props.fields) {
      if(field.type == 'autocomplete' && field.relationfield) {
        valuesfields[field.name] = field.relationfield;
      }
    }
    return valuesfields;
  });




  const handleFilter = function(){
    props.searchFilter(objectform);
    if(cleanInOK){
      handleClean()
    }
  }

  const handleClean = function(){
    for (let field of fields) {
      setValueform(currentValueform => ({...currentValueform, [field.name]: "" }))
      var newobject = setValueField(objectform,field.name,"");
      setObjectform(newobject);
    }

    props.cleanFilter(objectform);
  }

  const initValues = function(obj){

    for (let field of props.fields) {

        if(field.initValue){
            const value =  field.initValue(obj);
            console.log(field.name);
            console.log(value);
            setValueform(currentValueform => ({...currentValueform, [field.name]: value}))
        } else if(field.type == 'autocomplete'){
          if(field.multiple){
            var result = Array();
            var array = getValueField(obj,field.name);
            for (let index = 0; index < array.length; index++) {
              const element = array[index];
              if(element.id && element.id > 0){
                if(field.labeloption) {
                  result.push(element);
                } else {
                  result.push({key:element.id,name:element.name});
                }
              }
            }
            setValueform(currentValueform => ({...currentValueform, [field.name]: result}))
          } else {
            if(field.labeloption) {
              findInOptions(field,obj)
            } else {
              const valuef = getValueField(obj,field.name);
              let itemSelect = {key:valuef,name:valuef+""};
              setValueform(currentValueform => ({...currentValueform, [field.name]:  itemSelect}))
            }
          }
      } else if(field.type == 'image') {
        let valueField = getValueField(obj,field.name);
        if (valueField != null && valueField != ""){
          const url = process.env.REACT_APP_HOST+'/image' + (valueField.startsWith("/") ? "":"/");
          setValueform(currentValueform => ({...currentValueform, [field.name]: url + valueField }))
        }
      } else if(field.type == 'title' || field.type == 'table') {

      } else {
        if (field.inputtype && field.inputtype == "date") {
            setValueform(currentValueform => ({...currentValueform, [field.name]: (moment(Date.parse(getValueField(obj,field.name)))).format('YYYY-MM-DD') }))
        } else {
            setValueform(currentValueform => ({...currentValueform, [field.name]: getValueField(obj,field.name) }))
        }
      }

    }
  }

  const findInOptions = function(field, obj){
      findInOptionsWithOptions(options[field.name],field, obj)
  }

  const findInOptionsWithOptions = function(options,field, obj){
    const valuef = getValueField(obj,field.name);
    var item:any = null;
    if(options && valuef){
      options.forEach(element => {
            if (element[field.valueoption] == valuef) {
              item = element;
            }
      });

      if (item){
        setValueform(currentValueform => ({...currentValueform, [field.name]: item }))
        if(relationfield[field.name] && servicerelation[field.name]){
          updateRelationCombo(relationfield[field.name],servicerelation[field.name],valuef,field.name)
        }
      }
    }
  }


  const getValueField = (object,field) => {
    if(object){
      if (field.includes(".")){
        const parts = field.split(".");
        var objfield = object[parts[0]];
        if(Array.isArray(objfield)){
          var result = Array();
          for (let entry of objfield) {
            result.push(getValueField(entry,field.substring(parts[0].length +1)));
          }
          return result;
        } else {
          return getValueField(objfield,field.substring(parts[0].length +1));
        }
      } else {
          return object[field];
      }
    } else {
      return '';
    }
  };

  const setValueField = (object,field,value) => {
    if(object){
      if (field.includes(".")){
        const parts = field.split(".");
        var objfield = object[parts[0]];
        object[parts[0]] = setValueField(objfield,field.substring(parts[0].length +1),value);
        return object;
      } else {
        object[field] = value;
        return object;
      }
    } else {
      return object;
    }
  };

  const handleChange = function(event){
      let name = event.target.name;
      let value = event.target.value;

      setValueform(currentValueform => ({...currentValueform, [name]: value }))

      var field = null;
      for(let f of fields){
        if(f.name == name){
          field = f;
        }
      }
      var newobject = null;
      if(field && field['inputtype'] && field['inputtype'] == "date" && value && value != ""){
          var aux = (moment(Date.parse(value))).format('YYYY-MM-DD')+"T00:00:00Z";
          newobject = setValueField(objectform,name,aux);
      } else {
          newobject = setValueField(objectform,name,value);
      }
      if(setValueAux[name]){
          newobject = setValueAux[name](setValueField,newobject,setValueform)
      }
      setObjectform(newobject);
  }

  const onChangeEditor = function(content,name){
      setValueform(currentValueform => ({...currentValueform, [name]: content }))
      var newobject = setValueField(objectform,name,content);
      setObjectform(newobject);
  }


  const handleChangeCheckBox = function(event){
      let name = event.target.name;
      let value =  event.target.checked;
      setValueform(currentValueform => ({...currentValueform, [name]: value }))
      var newobject = setValueField(objectform,name,value);
      setObjectform(newobject);
  }

  const handleChangeAutocomplete = function(field,name,value){

      setValueform(currentValueform => ({...currentValueform, [name]: value}))

      if(setValueAux[name]) {
        var newobject =  setValueAux[name](setValueField,objectform,value)

        setObjectform(newobject);
        if(handlers[name]){
          handlers[name](value);
        }

        if(relationfield[name] && servicerelation[name]){
          updateRelationCombo(relationfield[name],servicerelation[name],value.key,name)
        }

        clearOtherFieldsRel(name)


      } else if (field.valueoption){
        debugger;
        if(value && (value[field.valueoption] || field.valueoption == "COMPLETE")) {
          var newobject:any = null;
          if(field.valueoption == "COMPLETE") {
             newobject = setValueField(objectform,name,value);
          } else {
            newobject = setValueField(objectform,name,value[field.valueoption]);
          }


          setObjectform(newobject);
          if(handlers[name]){
            handlers[name](value);
          }

          if(relationfield[name] && servicerelation[name] && value && value[field.valueoption]){
            updateRelationCombo(relationfield[name],servicerelation[name],value[field.valueoption],name)
          }
          clearOtherFieldsRel(name)
        } else {
          var newobject = setValueField(objectform,name,null);

          setObjectform(newobject);
          if(handlers[name]){
            handlers[name](value);
          }

          if(relationfield[name] && servicerelation[name] && value && value.key){
            updateRelationCombo(relationfield[name],servicerelation[name],value.key,name)
          }
          clearOtherFieldsRel(name)
        }
      } else if (value && (value.key || value.key === 0)){
        var newobject = setValueField(objectform,name,value.key);

        setObjectform(newobject);
        if(handlers[name]){
          handlers[name](value);
        }
        if(relationfield[name] && servicerelation[name]){
          updateRelationCombo(relationfield[name],servicerelation[name],value.key,name)
        }
        clearOtherFieldsRel(name)
      } else if(Array.isArray(value) && value[0]){

        const valuesSet = Array();
        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          valuesSet.push({id:element.key})
        }
        var newobject = setValueField(objectform,name,valuesSet);
        setObjectform(newobject);
        clearOtherFieldsRel(name)
      } else {
        var newobject = setValueField(objectform,name,"");
        setObjectform(newobject);
        if(handlers[name]){
          handlers[name](1234566879702);
        }
        clearOtherFieldsRel(name)
      }


  }

  const clearOtherFieldsRel = function(name){
    var clearFiels = clear[name];
    if (clearFiels && Array.isArray(clearFiels) && clearFiels.length){
      var newValues = {}
      clearFiels.map(n => {
          var isMultiple = false;
          fields.map(f => {
              if (f.name == n && f.multiple){
                isMultiple = true
              }
          });
          if (!isMultiple) {
              newValues[n] = "";
              var newobject = setValueField(objectform,n,"");
              setObjectform(newobject);
          } else {
              newValues[n] = [];
              var newobject = setValueField(objectform,n,[]);
              setObjectform(newobject);
          }
      });
      setValueform(currentValueform => ({...currentValueform, ...newValues}))
    }
  }

  const updateRelationCombo = function(field,service,key,name){

    if(relationSearchAux[name]) {
      var f:any = null;
      fields.forEach(element => {
         if (element.name == field){
           f = element
         }
      });
      relationSearchAux[name](setOptions,objectform,findInOptionsWithOptions,f)
    } else {
      service(key,(data, error) => {
        if(data && data.data){
          setOptions(currentOptions => ({...currentOptions, [field]: data.data }));
        }
      });
    }

  }

  const renderTitle = function(field){
      return <Typography className={classes.heading}> <label style={{fontSize:'1.3em',fontWeight:'bold'}}>{field.label}</label>  </Typography>
  }

  const renderInput = function(field){
      return <TextField id={field.name} name={field.name} value={valueform[field.name]} className={classes.inputform} onChange={handleChange} type={field.inputtype?field.inputtype:"text"}  inputProps={{step:field.step?field.step:"",  autoComplete: 'new-password', readOnly:field.readonly?field.readonly:false }}  InputLabelProps={{shrink: field.inputtype && (field.inputtype == 'date' || field.inputtype == 'number')}} />
  }

  const renderCheckBox = function(field){
      return  <FormControlLabel  control={ <Checkbox  checked={valueform[field.name]}   onChange={handleChangeCheckBox} name={field.name} color="primary" /> } label={field.label} />
  }


  const getLabelOption = function (option,field){
      if(field.labeloption) {
        return option[field.labeloption];
      } else if(option && option.name){
        if (typeof option.name === 'string'){
          return option.name
        } else {
          return ""
        }
      } else if (option && option.label && typeof option.label === 'string'){
        return option.label
      } else {
        return "";
      }
  }


  const renderAutocomplete = function(field){
    if (field.multiple){
      return <Autocomplete
            multiple
            id={field.name}
            options={options[field.name]}
            getOptionLabel={(option) => getLabelOption(option,field)}
            value={valueform[field.name]}
            onChange={(event,value) => handleChangeAutocomplete(field,field.name,value)}
            renderInput={(params) => (<TextField {...params}
            className={classes.inputform}  inputProps={{ ...params.inputProps, autoComplete: 'new-password' , readOnly:field.readonly?field.readonly:false }} />)}
          />
    } else {
      return <Autocomplete
            id={field.name}
            options={options[field.name]}
            getOptionLabel={(option) => getLabelOption(option,field)}
            value={valueform[field.name]}
            onChange={(event,value) => handleChangeAutocomplete(field,field.name,value)}
            renderInput={(params) => <TextField {...params}
            className={classes.inputform}  inputProps={{ ...params.inputProps, autoComplete: 'new-password'  , readOnly:field.readonly?field.readonly:false }} />}
          />
    }

  }


  const onChangeImage = function(event,field){
    uploadService.upload(event.target.files[0],(data, error) => {
        if( data && data.data != null && data.data != "" ){
            var newobject = setValueField(objectform,field,"/"+data.data);
            setObjectform(newobject);
            const url = process.env.REACT_APP_HOST+'/image';
            setValueform(currentValueform => ({...currentValueform, [field]: url + "/"+data.data }))
        }
    })
  }

  const onChangeImageDelete  = function(event,field){
      var newobject = setValueField(objectform,field,null);
      setObjectform(newobject);
      setValueform(currentValueform => ({...currentValueform, [field]: null }))
  }

  const renderInputImage = function(field){

      return <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
              <img src={valueform[field.name]} style={{  width:'auto',  height:'50px',  maxWidth: '100%'}} />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Button variant="contained" component="label"> Subir Imagen <input type="file" hidden onChange={(event) => onChangeImage(event,field.name)}/> </Button>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Button variant="contained" component="label" onClick={(event) => onChangeImageDelete(event,field.name)} style={{background:"darkred",color:"white"}}> Borras Imagen </Button>
        </Grid>
      </Grid>
  }

  const renderTextarea = function(field){
    return <Grid container spacing={3}>
        <Grid item sm={12} xs={12} style={{textAlign:"left"}}>
            {field.label}
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextareaAutosize id={field.name} name={field.name}  value={valueform[field.name]} className={classes.inputform} onChange={handleChange}  rowsMin={4} readOnly={field.readonly?field.readonly:false}/>
        </Grid>
      </Grid>
  }

  const renderEditor = function(field){
    return <Grid container spacing={3}>
        <Grid item sm={12} xs={12} style={{textAlign:"left"}}>
            {field.label}
        </Grid>
        <Grid item sm={12} xs={12}>
        <Editor
           apiKey="vnodym0tt7bwz6fhfd2gdlhstcj2esz3atkwxpkk4k7tsjdm"
           value={valueform[field.name]}
           init={{
             height: 500,
             menubar: false,
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar: 'undo redo | formatselect | ' +
             'bold italic backcolor | alignleft aligncenter ' +
             'alignright alignjustify | bullist numlist outdent indent | ' +
             'removeformat | help | image | media' ,
             images_upload_handler: async function(	blobInfo: any,	success: any,failure: any) {
          			let imageFile = new FormData();
          			imageFile.append("files[]", blobInfo.blob());

                uploadService.upload(blobInfo.blob(),(data, error) => {
                    if( data && data.data != null && data.data != "" ){
                        const url = process.env.REACT_APP_HOST+'/image/'+data.data ;
                        success(url);
                    }
                })
          		},
             content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
           }}
           onEditorChange={(content) => onChangeEditor(content,field.name)}
         />
        </Grid>
      </Grid>
  }

  const updateTable = function(name,rows){
        var newobject = setValueField(objectform,name,rows);

        if(setValueAux[name]){
            newobject = setValueAux[name](setValueField,newobject,rows, setValueform)
        }

        setObjectform(newobject);

  }


  const renderTable = function(field){
    return  <FormTable
         rows={ getValueField(objectform,field.name)}
         initrow={field.initrow}
         fields={field.fields}
         headers={field.headers}
         fieldId={field.fieldId}
         fieldName={field.name}
         onlyread={field.onlyread?field.onlyread:false}
         updateRows={updateTable} />
  }

  const renderField = function(field){
      switch (field.type) {
        case 'input':
          return renderInput(field);
        case 'textarea':
          return renderTextarea(field);
        case 'autocomplete':
          return renderAutocomplete(field);
        case 'image':
          return renderInputImage(field);
        case 'checkbox':
            return renderCheckBox(field);
        case 'editor':
            return renderEditor(field);
        case 'title':
          return renderTitle(field);
      case 'table':
          return renderTable(field);
        default:
          return renderInput(field);
      }
  }

  const loadCombos = function(){
    for (let field of fields) {
      if(field.type == 'autocomplete' && field.service) {
          field.service((data, error) => {

            if(data){
              setOptions(currentOptions => ({...currentOptions, [field.name]: data }));
              findInOptionsWithOptions(data,field,objectform)
            }
          });
      }
    }
  }

  useEffect(() => {
    loadCombos();
  }, []);

  useEffect(() => {

    setObjectform(props.objectform);
    initValues(props.objectform);
    for(var field of fields){
      if (field.type == 'autocomplete' && field.relationfield){
        var v = getValueField(props.objectform,field.name);
        if (v){
          updateRelationCombo(field.relationfield,field.servicerelation,v,field.name)
        }
      }
    }

  }, [props.objectform]);


  useEffect(() => {

    if(props.forcesave){
      handleFilter();
    }
  }, [props.forcesave]);



  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {fields.map((field) => (
          <Grid item sm={field.col?field.col:3}>
            <div>
              <label style={{color:"white", fontSize:"1.2em", fontWeight: 800}}>{field.label}</label>
            </div>
            <div>
              {renderField(field)}
            </div>

          </Grid>
       ))}
      </Grid>
      <Grid container>
        <Grid item sm={12} style={{textAlign:'right', marginTop: '50px'}}>
          {props.haveMoreActions && props.moreActions()}
          {!isHiddenCancel? <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={handleClean}> {labelcancel} </Button> : ''}
          {!isHiddenSave? <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={handleFilter}> {labelsave} </Button> : ''}

        </Grid>
      </Grid>
    </div>
  );
}
