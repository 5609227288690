import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import { shadows } from '@material-ui/system';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useStyles } from "../../layout/styles";
import {Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MovieIcon from '@material-ui/icons/Movie';
import {getValueField, getImage, getNameToUrl,getImageCell} from '../../utils/Utils';


const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));


function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <div className={classes.root}>

    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  tablecontainer: {
    boxShadow: "none",
  },
  bodymodal: {
    margin: "auto",
    width: "500px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    padding: "5em",
    marginTop: "100px",
    textAlign: "center"
  },
  panelImg:{
    backgroundSize: "100% auto !important",
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    height:"200px",
    maxWidth: '100%',
  },
  paginationrental:{
    display: "block",
    border: "0px solid white",
    "& button ": {
      backgroundColor: "white",
      color: '#2f3354',
      borderRadius: '3px',
      marginLeft:'0.5em'
    },
    "& select ": {
      backgroundColor: "white",
      color: '#2f3354',
      borderRadius: '3px',
      marginLeft:'0.5em'
    }
  }
});

export default function TableHouse(props) {
  const classes = useStyles2();
  const classesgeneral = useStyles();
  const [page, setPage] = useState(props.page);
  const [idrow, setIdrow] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage?props.rowsPerPage:5);
  const [open, setOpen] = useState(false);
  const rows = props.rows;
  const rowsTotal = props.rowsTotal;
  const headers = props.headers;
  const fieldId = props.fieldId;
  const handleChangePageParent = props.changePage;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleChangePageParent(newPage,rowsPerPage);
  };

  useEffect( () => {
        setPage(props.page);
  }, [props.page])


  const handleChangeRowsPerPage = (event) => {
    const sizePage = parseInt(event.target.value, 10);
    setRowsPerPage(sizePage);
    setPage(0);
    handleChangePageParent(0,sizePage);
  };



   const handlerDobleClick = function(id){
     if (props.selectHandler) {
       props.selectHandler(id);
     }
   }

   const rowRental = (row) =>{
     return   <Grid  item xs={12} sm={6} md={3}  style={{textAlign:'center', marginBottom: '10px', padding:'1em', cursor: 'pointer'}} onClick={() => props.selectHandler(row['IDINMUEBLE'])} >
                <Box  style={{minHeight:'100%',boxShadow:"4px 8px 5px 0px #B7B5B7", border:"1px solid #B7B5B7" }} sx={{ boxShadow: 2 }}>
                  <Grid container spacing={3}  style={{padding:'12px 10px'}} >
                      <Grid item xs={12} sm={12} style={{textAlign:'center', marginBottom: '0px',padding:'0 12px'}}>
                         {row['FOTO_PRIN'] !=null && row['FOTO_PRIN']['URL'] != null ? <div  className={classes.panelImg}  style={{ background: "url('"+row['FOTO_PRIN']['URL']+"')"}}></div> : ""}
                         {(row['FOTO_PRIN'] == null || row['FOTO_PRIN']['URL'] == null) && row['FOTOS'] !=null && row['FOTOS'][0] ? <div className={classes.panelImg} style={{ background: "url('"+row['FOTOS'][0]['URL']+"')"}}></div> : ""}
                      </Grid>
                      <Grid item xs={12} sm={12} style={{textAlign:'left', marginBottom: '0px',padding:'0.5em 12px', backgroundColor:"#EDEDED"}}>
                         <Typography component="p" style={{color:"black", fontWeight:"bold", fontSize:"0.9em"}}>
                            {getValueField(row,'TITULO_INMUEBLE')}
                         </Typography>
                         <Typography component="p" style={{color:"#7C7C7C", fontWeight:"bold", fontSize:"1.1em"}}>
                            {getValueField(row,'PRECIO')} €
                         </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{textAlign:'left', marginBottom: '10px', marginTop:'10px',padding:'0 12px'}}>
                         <label style={{display:"inline-block",width:"80%"}}>Baños</label><label style={{textAlign:"right",display:"inline-block",width:"20%"}}>{getValueField(row,'BANOS')}</label><br/>
                         <label style={{display:"inline-block",width:"80%"}}>Dormitorios</label><label style={{textAlign:"right",display:"inline-block",width:"20%"}}>{getValueField(row,'HABITACIONES')}</label>
                         <label style={{display:"inline-block",width:"80%"}}>Metros</label><label style={{textAlign:"right",display:"inline-block",width:"20%"}}>{getValueField(row,'SUPERFICIE')} m<sup>2</sup></label>
                      </Grid>
                  </Grid>
                </Box>
     </Grid>
   }

  const changePage = (diff) => {
        const p = page + diff;
        setPage(p);
        handleChangePageParent(p,rowsPerPage);
  }

  return (
    <Grid container spacing={3}>
      {(rows).map((row) => (
          rowRental(row)
      ))}
      <Grid item sm={12} style={{textAlign:'center', marginBottom: '10px'}}>
          <IconButton onClick={() => changePage(-1)} disabled={page === 0} aria-label="previous page" style={{backgroundColor:"#E60012", display:"inline-block", width:"60px", height:"60px", marginRight:"0.5em", color:"white"}}>
              <KeyboardArrowLeft />
          </IconButton>
          <IconButton  onClick={() => changePage(1)} disabled={rows.length < 20} aria-label="next page" style={{backgroundColor:"#E60012", display:"inline-block", width:"60px", height:"60px", marginLeft:"0.5em", color:"white"}}>
              <KeyboardArrowRight />
          </IconButton>
      </Grid>
    </Grid>
  );
}
